import {
    Configuration,
    InteractionRequiredAuthError,
    IPublicClientApplication,
} from '@azure/msal-browser';

export const tenant = process.env.TENANT_ID || '5d49a660-c20c-45c1-9d68-0a244376a6c3';
const bimApiAppName = process.env.BIM_API_APP_NAME || 'internal-bim-dev';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.CLIENT_ID || '2c0e31f3-6fa4-44c7-a9b0-c946abad1850', // gui
        authority: `https://login.microsoftonline.com/${tenant}/`,
        redirectUri: process.env.REDIRECT_URI || 'http://localhost:1234',
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export class BimAuth {
    static msalInstance: IPublicClientApplication;
    static lastToken: string | undefined;

    static get token() {
        return BimAuth.lastToken
            ? BimAuth.lastToken
            : (async () => {
                  BimAuth.lastToken = await BimAuth.renewToken();
                  return BimAuth.lastToken;
              })();
    }

    static async renewToken() {
        console.log('[BimAuth] token renewal:');
        // Credentials request to get access token to BIM API
        const credentialsRequest = {
            scopes: [`api://${bimApiAppName}/bim.readwrite.all`],
        };

        const tok = await BimAuth.msalInstance
            .acquireTokenSilent(credentialsRequest)
            .catch((error) => {
                console.warn(error);
                console.warn('silent token acquisition fails. acquiring token using popup');
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return BimAuth.msalInstance
                        .acquireTokenPopup(credentialsRequest)
                        .then((response) => {
                            console.log(response);
                            return response;
                        })
                        .catch(console.error);
                } else {
                    console.warn(error);
                }
            });

        const { accessToken } = tok || {};
        BimAuth.lastToken = accessToken;
        return accessToken;
    }
}
