import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { BimAdmin, BimAdminHeader } from './components/BimAdmin';
import { AuthWrapper } from './components/Auth';

export function App() {
    return (
        <>
            <AuthWrapper>
                <BimAdminHeader />
                <BimAdmin />
            </AuthWrapper>
        </>
    );
}
