import { useEffect, useState, useMemo } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { BimAuth, msalConfig } from '../common/Auth';
import { BimTestApi } from '../api/bim';

// eslint-disable-next-line react/prop-types
export const Auth = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const [token, setToken] = useState();

    useEffect(() => {
        BimAuth.token.then((val) => setToken(val));
        // make Test API default
        BimTestApi.getSingleton().makeDefault();
    }, [accounts]);

    if (accounts.length > 0) {
        // Need to set active account
        instance.setActiveAccount(accounts[0]);

        return (
            <>
                {token ? (
                    <>
                        {children}
                        {/* BIM API used token: {token} */}
                    </>
                ) : (
                    <>
                        <span>There are currently {accounts.length} users signed in!</span>
                    </>
                )}
            </>
        );
    } else if (inProgress === 'login') {
        return <span>Login is currently in progress!</span>;
    } else {
        return (
            <>
                <span>There are currently no users signed in!</span>
                <button onClick={() => instance.loginPopup()}>Login</button>
            </>
        );
    }
};

// eslint-disable-next-line react/prop-types
export const AuthWrapper = ({ children }) => {
    const pca = useMemo(() => new PublicClientApplication(msalConfig), []);
    BimAuth.msalInstance = pca;

    return (
        <MsalProvider instance={pca}>
            <Auth>{children}</Auth>
        </MsalProvider>
    );
};
